import { createContext, useContext, useReducer, useEffect } from 'react';

const MyContext = createContext();

const initialState = {
    favoriteImages: [],
    animePlay: true,
    shareModalState: 'closed',
    lightBoxState: 'closed',
    consent: false,
    downloadImage: { download: false }
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_DOWNLOAD_IMAGE':
            const { payload: downloadImage } = action;

            return {
                ...state,
                downloadImage: downloadImage,
            };

        case 'SET_FAVORITE':
            const { payload: addAllToFavourite } = action;

            return {
                ...state,
                favoriteImages: addAllToFavourite,
            };

        case 'SET_CREATION':
            const { payload: setCreation } = action;

            return {
                ...state,
                creation: setCreation,
            };

        case 'SET_SHARE_IMAGE':
            const { payload: setShareImage } = action;

            return {
                ...state,
                shareImage: setShareImage,
            };

        case 'SET_SHAREMODAL':
            const { payload: toggleShareModal } = action;

            return {
                ...state,
                shareModalState: toggleShareModal,
            };

        case 'SET_LIGHTBOX':
            const { payload: toggleLightBox } = action;

            return {
                ...state,
                lightBoxState: toggleLightBox,
            };

        case 'TOGGLE_FAVORITE':
            const { payload: toggledImage } = action;

            const isFavorite = state.favoriteImages.some(
                (favoriteImage) => favoriteImage === toggledImage
            );

            return {
                ...state,
                favoriteImages: isFavorite
                    ? state.favoriteImages.filter(
                        (favoriteImage) => favoriteImage !== toggledImage
                    )
                    : [...state.favoriteImages, toggledImage],
            };

        case 'SET_ANIME_PLAYSTATE':
            const { payload: animePlayState } = action;

            return {
                ...state,
                animePlay: animePlayState,
            };

        default:
            return state;
    }
};


const MyProvider = ({ children, consent }) => {
    const [appState, dispatch] = useReducer(reducer, initialState);
    const [bgState, dispatchBg] = useReducer(reducer, true);

    appState.consent = consent;
    
    useEffect(() => {
        const body = document.querySelector('body');
        const isOverlayOpen = appState.shareModalState === 'open' || appState.lightBoxState === 'open';
        if (body && isOverlayOpen) {
            body.style.overflow = 'hidden'
        } else {
            body.style.overflow = 'inherit'
        }
    }, [appState])
    return (
        <MyContext.Provider value={{ appState, dispatch, bgState, dispatchBg }}>
            {children}
        </MyContext.Provider>
    );
};

const useMyContext = () => {
    const context = useContext(MyContext);
    if (!context) {
        throw new Error('useMyContext must be used within a MyProvider');
    }
    return context;
};

export { MyProvider, useMyContext };
