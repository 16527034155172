import React from "react"
import CheckIcon from '@mui/icons-material/Check';
import './GardenStyle.scss';

export default function GaderdenStyle({ index, selected, name, choice, image, handeClick }) {

    const handleStyleClick = () => {
        handeClick({ choice: choice, name: name, index: index })
    }

    return (
        <button onClick={handleStyleClick} className="gardenstyle--card">
            <div className={`checkmark  ${selected ? "selected" : ""}`}><CheckIcon /></div>
            <img className={`${selected ? "selected" : ""}`} alt="garden-style" src={image} />
            <p className={`style--desc ${selected ? "selected" : ""}`}>
                {name}
            </p>
        </button>
    )
}