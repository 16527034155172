import React from 'react';
import './SocialShare.scss';
import Button from '../Button/Button';
import { useMyContext } from '../../state/Context';
import CancelIcon from '@mui/icons-material/Cancel';
import { getCookieConsentValue } from 'react-cookie-consent';

export default function SocialShare() {
    const { appState, dispatch } = useMyContext();

    const socials = {
        'whatsapp': `https://wa.me/?text=http://staging.tuinainemer.be`,
        'facebook': `https://www.facebook.com/sharer/sharer.php?u=http://staging.tuinainemer.be`,
    }

    const handleSocialShare = (social) => {
        window.open(socials[social], '_blank').location.href = socials[social];
        !!getCookieConsentValue('CookieConsent') && window.dataLayer.push({
            event: "share",
            platform: social
        },)
        console.log(window.dataLayer)
    }

    const handleClick = () => {
        dispatch({
            type: 'SET_SHAREMODAL',
            payload: 'closed',
        });
    }

    return (
        <div className={`social-share ${appState.shareModalState}`}>
            <div onClick={handleClick} className="close"><CancelIcon /></div>
            <div className='share--inner'>
                <Button handleClick={() => handleSocialShare('facebook')} type={'solid'} text="Facebook" iconLeft={'facebook'} />
                <Button handleClick={() => handleSocialShare('whatsapp')} type={'solid'} text="whatsapp" iconLeft={'whatsapp'} />
            </div>
        </div>
    )
}