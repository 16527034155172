import './Legal.scss';

export default function Privacy() {
    return (
        <div className='legal'>
            <h1>Privacyverklaring</h1>
            <div class="legaltext"><h2>Algemeen</h2>
                <ul>
                    <li>Deze Privacyverklaring regelt de verwerking van uw persoonsgegevens als een onderdeel van uw gebruik van onze Website (de “Website”) en/of alle erop aangeboden diensten of diensten die daarmee verband houden (gezamenlijk aangeduid als de “Diensten”). Deze verwerking wordt uitgevoerd door het Vlaams Centrum voor Agro- en Visserijmarketing (VLAM) vzw als verantwoordelijke voor de verwerking, met zetel te 1000 Brussel aan de Simon Bolivarlaan 17 bus 411 op de 7de verdieping en met ondernemingsnummer 0454.423.323, in overeenstemming met de toepasselijke wetgeving inzake de bescherming van persoonsgegevens, dit behoudens expliciet anders aangegeven, in welk geval niet VLAM, maar een expliciet aangeduide derde de verantwoordelijke voor de verwerking is.<br></br>
                        In deze Privacyverklaring wordt, onder andere, aangegeven welke gegevens op de website door VLAM worden verzameld, hoe VLAM deze gegevens gebruikt, hoe de gegevens worden beveiligd en welke uw persoonlijke rechten aangaande zijn.</li>
                    <li>Door gebruik te (blijven) maken van deze Website en/of de Diensten, bevestigt u integraal kennis te hebben genomen van deze Privacyverklaring en hiermee integraal akkoord te gaan. Indien u hiermee niet integraal akkoord gaat, verlaat u nu op eigen initiatief de website en maakt u op geen enkele wijze gebruik van deze Website en/of de Diensten.</li>
                    <li>VLAM behoudt zich het recht voor om de Privacyverklaring (regelmatig) te wijzigen volgens eigen inzicht. Dergelijke wijzigingen, toevoegingen of vervangingen zullen onder uw aandacht worden gebracht via onze Website en/of desgevallend via de Diensten. Als u het niet eens bent met deze wijzigingen, toevoegingen of vervangingen, vragen wij u een e-mail te sturen naar <a href="mailto:multimedia@vlam.be" class="spamspan">multimedia@vlam.be</a>. Indien wij hiervan geen mededeling hebben ontvangen binnen vijf (5) werkdagen nadat de wijzigingen, aanvullingen of vervangingen via onze Website of Diensten aan u zijn meegedeeld, wordt u geacht alle wijzigingen, aanvullingen of vervangingen te hebben aanvaard. Hou er rekening mee dat de intrekking van uw akkoord met de voorwaarden kan impliceren dat u niet langer in staat bent om de Website en/of Diensten geheel of gedeeltelijk te gebruiken.</li>
                    <li>Door de privacy opt-in checkbox aan te klikken op formulieren op de Website, verklaart u dat u inderdaad deze Privacyverklaring gelezen heeft en hiermee instemt. U verklaart dat u begrijpt met welke doeleinden uw persoonsgegevens worden verwerkt. U stemt er ook mee in dat uw voortgezet gebruik van de Website en/of de Diensten wordt begrepen als een voortgezette toestemming. U kan uw toestemming op elk ogenblik intrekken door een verzoek te richten via e-mail naar <a href="mailto:multimedia@vlam.be" class="spamspan">multimedia@vlam.be</a>.</li>
                    <li>U garandeert dat de gegevens die u aan VLAM meedeelt u toebehoren, of althans dat u toestemming heeft om ze te gebruiken en aan VLAM mee te delen. VLAM kan u de toegang tot haar Website(s) en/of Diensten of delen daarvan ontzeggen, tijdelijk of definitief, wanneer u gebruik maakt van valse gegevens of van gegevens van derden zonder hun toestemming.</li>
                    <li>Indien er op onze Website of in onze Diensten iets is wat niet werkt zoals u het verwacht als het gaat om uw privacy, kunt u gerust contact met ons opnemen via helpdesk. VLAM vzw, Koning Albert II-laan 15 bus 411, 1210 Brussel (postadres) of via e-mail <a href="mailto:multimedia@vlam.be" class="spamspan">multimedia@vlam.be</a> of telefonisch: 02 553 20 00.</li>
                    <li>Deze privacyverklaring werd laatst bijgewerkt op 24.04.2018.</li>
                </ul>

                <h2>Welke gegevens en waarom we deze gegevens verwerken</h2>

                <ul>
                    <li>VLAM verzamelt gegevens om effectief te kunnen werken, om u de beste ervaringen met onze Website en Diensten te kunnen bieden, om met u in contact te treden, om u de producten of diensten te kunnen leveren die u hebt gevraagd of waarvoor u zich heeft geregistreerd, om u te informeren over het eigen aanbod aan producten en diensten (zoals o.m. data waarop VLAM evenementen plaatsvinden; wedstrijden, etc.), voor het personaliseren van het eigen aanbod aan producten en diensten en voor direct marketing doeleinden.</li>
                </ul>

                <p>U geeft een aantal van deze gegevens rechtstreeks aan ons door, bijvoorbeeld wanneer u zich inschrijft op een Dienst zoals de nieuwsbrief van de website, wanneer u deelneemt aan een wedstrijd, wanneer u contact met ons opneemt voor ondersteuning, enz. Een deel van de gegevens verkrijgen we door vast te leggen hoe u met onze Website en Diensten omgaat, bijvoorbeeld door het gebruik van technologieën zoals cookies. Hou er aldus rekening mee dat VLAM zogenaamde ‘cookies’ of gelijkaardige technologieën kan gebruiken als deel van de Website en/of de Diensten. Cookies zijn kleine tekstbestanden die geplaatst worden op de harde schijf van een toestel, en die bepaalde informatie bevatten, waaronder soms persoonsgegevens. Voor meer informatie betreffende ons gebruik van cookies, gelieve onze <a href="https://www.tuinaannemer.be/cookieverklaring">Cookieverklaring</a> te lezen.</p>

                <ul>
                    <li>Wanneer u de Website en/of Diensten gebruikt, verwerken we persoonsgegevens die op u betrekking hebben (zoals bijvoorbeeld uw voornaam, familienaam, nickname, e-mailadres, geslacht, straatnaam, straatnummer, postcode, postadres, geboortedatum, beroep, telefoonnummer en afbeelding) en dit telkens voor de nuttige bewaringstermijn. Zulke persoonsgegevens omvatten:&nbsp;&nbsp;</li>
                </ul>

                <table border="1" cellpadding="1" cellspacing="1">
                    <tbody>
                        <tr>
                            <td><strong>Wanneer</strong></td>
                            <td><strong>Welke gegevens</strong></td>
                            <td><strong>Waarom (doel)</strong></td>
                            <td><strong>Wettelijke grondslag</strong></td>
                        </tr>
                        <tr>
                            <td>
                                <p>Gebruik van website</p>

                                <p>&nbsp;</p>
                            </td>
                            <td>
                                <p>- IP-adres<br></br>
                                    - Cookies</p>
                            </td>
                            <td>
                                <p>- Klantenbeheer<br></br>
                                    - Verbetering van de services</p>
                            </td>
                            <td>
                                <p>- Gerechtvaardigd belang<br></br>
                                    - Toestemming</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Gebruik van Diensten</p>

                                <p>&nbsp;</p>
                            </td>
                            <td>
                                <p>Persoonlijke identificatiegegevens</p>
                            </td>
                            <td>
                                <p>- Klantenbeheer<br></br>
                                    - Verwerking van de aanvraag<br></br>
                                    - Direct Marketing</p>
                            </td>
                            <td>
                                <p>- Toestemming</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Solliciteren op een vacature</p>

                                <p>&nbsp;</p>
                            </td>
                            <td>
                                <p>Persoonlijke identificatiegegevens</p>

                                <p>&nbsp;</p>
                            </td>
                            <td>
                                <p>- Verwerking van de aanvraag<br></br>
                                    - Opname in een actueel wervingsbestand</p>
                            </td>
                            <td>
                                <p>- Toestemming</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Het downloaden van aangeboden marketing materiaal</p>

                                <p>&nbsp;</p>
                            </td>
                            <td>
                                <p>- IP-adres<br></br>
                                    - Cookies</p>
                            </td>
                            <td>
                                <p>- Verwerking van aanvraag<br></br>
                                    - Verbetering van de services</p>
                            </td>
                            <td>
                                <p>- Gerechtvaardigd belang<br></br>
                                    - Toestemming</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Ons contacteren</p>

                                <p>&nbsp;</p>
                            </td>
                            <td>
                                <p>Persoonlijke identificatiegegevens</p>
                            </td>
                            <td>
                                <p>- Verwerking van aanvraag</p>
                            </td>
                            <td>
                                <p>Gerechtvaardigd belang</p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <p>&nbsp;</p>

                <ul>
                    <li>In principe verkrijgen wij de hierboven genoemde persoonsgegevens rechtstreeks van u. Wij sturen geen van de persoonlijke gegevens die u via de Website verstrekt door aan providers van social media, tenzij u hiermee instemt. Naast de bovengenoemde doeleinden kunnen wij uw persoonlijke gegevens ook verwerken:<br></br>
                        <br></br>
                        a) Om u op een gepersonaliseerde en efficiënte manier te voorzien van de informatie over producten en diensten die u aanvraagt, hetzij via de Website, e-mail, telefoon of social media kanalen.<br></br>
                        b) Voor direct marketing-doeleinden, d.w.z. om u te kunnen voorzien van gerichte communicatie, promoties, aanbiedingen en andere advertenties van ons of onze geselecteerde partners. Hiervoor zullen wij uw voorafgaande toestemming vragen.<br></br>
                        c) Voor het uitvoeren van statistische analyses om onze Website en/of Diensten te verbeteren, of het ontwikkelen van nieuwe producten of diensten.<br></br>
                        d) Om te verstrekken aan een financiële instelling of betalingsdienstaanbieder, om uw financiële instelling en de betalingsdienstaanbieder in staat te stellen aan hun wettelijke verplichtingen te voldoen.<br></br>
                        e) Om over te dragen aan de politie of de gerechtelijke autoriteiten als bewijs van mogelijke misdrijven of indien er gegronde verdenkingen bestaan van een onrechtmatige daad of misdrijf door u gepleegd door middel van uw registratie bij of gebruik van de Website of de Diensten.<br></br>
                        &nbsp;</li>
                    <li>Als en wanneer uw registratie op de Website of gebruik van de Website of Diensten kan worden beschouwd (a) als een schending van de voorwaarden of de intellectuele eigendomsrechten of enig ander recht van een derde partij, (b) een bedreiging voor de veiligheid of integriteit van de Diensten, (c) een gevaar voor de Website of Diensten of systemen van ons of van onze onderaannemers als gevolg van virussen, Trojaanse paarden, spyware, malware, of enige andere vorm van kwaadaardige code, of (d) op welke manier dan ook illegaal of onrechtmatig, discriminerend of beledigend is, mogen wij uw gegevens verwerken in het belang van onszelf, onze partners of derden.</li>
                </ul>

                <h2>Met wie en waarom wij gegevens delen</h2>

                <ul>
                    <li>Wij verzenden uw persoonsgegevens niet op een identificeerbare manier naar derden als dit niet vereist is om de Diensten te verlenen, zonder uw uitdrukkelijke toestemming daartoe.</li>
                    <li>Wij kunnen een beroep doen op externe verwerkers om u de Website en/of Diensten aan te bieden. Wij zorgen ervoor dat derde verwerkers uw persoonsgegevens alleen namens ons, en op schriftelijke instructie van ons, mogen verwerken. Wij garanderen dat alle externe verwerkers met de nodige zorgvuldigheid worden geselecteerd zodat wij kunnen rekenen op de veiligheid en integriteit van uw persoonsgegevens.</li>
                    <li>Wij kunnen geanonimiseerde en/of geaggregeerde gegevens overmaken aan andere organisaties die deze gegevens kunnen gebruiken om producten en diensten te verbeteren en de marketing, presentatie&nbsp; en verkoop van producten en diensten op maat te organiseren.</li>
                </ul>

                <h2>Waar wij gegevens verwerken</h2>

                <ul>
                    <li>Wijzelf en onze externe verwerkers zullen uw identificeerbare persoonsgegevens alleen in de EER verwerken.</li>
                    <li>Wij kunnen uw geanonimiseerde en/of geaggregeerde gegevens overdragen aan organisaties buiten de EER. Indien een dergelijke overdracht plaatsvindt, zullen wij ervoor zorgen dat er passende waarborgen zijn om de veiligheid en integriteit van uw persoonlijke gegevens te garanderen, en dat alle rechten met betrekking tot persoonsgegevens gegarandeerd worden die u zou kunnen genieten onder het toepasselijk dwingend recht.</li>
                    <li>Als er een overdracht van uw persoonsgegevens en/of geanonimiseerde en/of geaggregeerde gegevens plaatsvindt, wordt het volgende wettelijke beschermingsmechanisme ingevoerd:</li>
                </ul>

                <table border="1" cellpadding="1" cellspacing="1">
                    <tbody>
                        <tr>
                            <td><strong>Welke</strong></td>
                            <td><strong>Land buiten EER</strong></td>
                            <td><strong>Wettelijke doorgiftemechanisme</strong></td>
                        </tr>
                        <tr>
                            <td>Google</td>
                            <td>USA</td>
                            <td>EU - US Privacy Shield</td>
                        </tr>
                    </tbody>
                </table>

                <p>&nbsp;</p>

                <h2>Hoe wij gegevens verwerken</h2>

                <ul>
                    <li>Wij doen ons uiterste best om alleen de persoonsgegevens te verwerken die nodig zijn om de in deze Privacyvoorwaarden vermelde doeleinden te bereiken. Wij zullen uw persoonsgegevens op een wettelijke, eerlijke en transparante manier verwerken. Wij doen ons uiterste best om de persoonsgegevens accuraat en up-to-date te houden.</li>
                    <li>Uw persoonsgegevens worden alleen verwerkt zolang als nodig is om de in deze Privacyvoorwaarden vermelde doeleinden te bereiken of tot het moment waarop u uw toestemming voor de verwerking ervan intrekt. Hou er rekening mee dat de intrekking van de toestemming kan impliceren dat u niet langer in staat bent om de Website en/of Diensten geheel of gedeeltelijk te gebruiken. Als u zich bij onze Website heeft geregistreerd (indien van toepassing), zullen wij uw persoonsgegevens verwijderen als u uw profiel verwijdert, tenzij een wettelijke of reglementaire verplichting of een gerechtelijk of administratief bevel ons dat zou verhinderen.</li>
                    <li>Wij zullen de gepaste technische en organisatorische maatregelen nemen om uw persoonsgegevens veilig te houden tegen ongeoorloofde toegang of diefstal en tegen onopzettelijk verlies, manipulatie of vernietiging. Toegang door ons personeel of personeel van onze externe verwerkers is strikt gelimiteerd en alleen mogelijk op een need-to-know-basis en is onderworpen aan strikte geheimhoudingsverplichtingen. U begrijpt echter dat de zorg voor veiligheid en beveiliging slechts inspanningsverplichtingen naar beste vermogen zijn, die nooit kunnen worden gegarandeerd.&nbsp;</li>
                </ul>

                <h2>Uw rechten</h2>

                <ul>
                    <li>U heeft het recht om inzage te vragen tot alle persoonsgegevens die wij over u verwerken. Verzoeken om inzage die klaarblijkelijk worden ingediend met oog op het veroorzaken van overlast of schade voor ons, worden echter niet behandeld.</li>
                    <li>U heeft het recht om te vragen dat alle persoonsgegevens over u die onjuist of onnauwkeurig zijn, kosteloos worden gecorrigeerd. Indien een dergelijk verzoek wordt ingediend, dient u bij dit verzoek ook het bewijs te voegen dat de persoonsgegevens waarvoor correctie wordt gevraagd, onjuist zijn.</li>
                    <li>U heeft het recht om uw eerder gegeven toestemming voor de verwerking van uw persoonsgegevens in te trekken. U kunt uw toestemming te allen tijde intrekken door een e-mail met dit verzoek te sturen naar <a href="mailto:multimedia@vlam.be" class="spamspan">multimedia@vlam.be</a> of door uw profiel te verwijderen (indien van toepassing), of indien u uw toestemming voor de verwerking van uw persoonsgegevens voor een bepaalde Dienst wenst in te trekken kan u de daartoe desgevallend voorziene uitschrijfmogelijkheden benutten.</li>
                    <li>U heeft het recht om te vragen dat persoonsgegevens met betrekking tot u worden verwijderd als deze niet langer nodig zijn in het licht van de doeleinden die in deze Privacyvoorwaarden worden uiteengezet of als u uw toestemming voor de verwerking ervan intrekt. U dient er echter rekening mee te houden dat een verzoek tot verwijdering door ons zal worden beoordeeld in het licht van wettelijke of reglementaire verplichtingen of administratieve of gerechtelijke bevelen die ons kunnen verhinderen de betreffende persoonsgegevens te verwijderen.</li>
                    <li>In de plaats van verwijdering kunt u ook vragen dat wij de verwerking van uw persoonsgegevens beperken indien (a) u de juistheid van die gegevens betwist, (b) de verwerking onwettig is of (c) de gegevens niet langer nodig zijn voor de genoemde doeleinden, maar u ze nodig heeft om uzelf te verdedigen in gerechtelijke procedures.</li>
                    <li>U heeft het recht om zich te verzetten tegen de verwerking van persoonsgegevens indien u kunt aantonen dat er ernstige en gerechtvaardigde redenen zijn die verband houden met bijzondere omstandigheden en die een dergelijk verzet rechtvaardigen. Als de beoogde verwerking echter als direct marketing wordt aangemerkt, heeft u het recht zich kosteloos en zonder rechtvaardiging te verzetten tegen een dergelijke verwerking.</li>
                    <li>Wanneer uw persoonsgegevens worden verwerkt op basis van toestemming of op basis van een contract en de verwerking met geautomatiseerde middelen gebeurt, heeft u het recht om de door u aan VLAM verstrekte persoonsgegevens in een gestructureerd, algemeen gebruikt en machinaal leesbaar formaat te ontvangen en, indien technisch mogelijk, heeft u het recht deze gegevens rechtstreeks door te geven aan een andere dienstverlener. De technische haalbaarheid hiervan wordt uitsluitend beoordeeld door VLAM.</li>
                    <li>Als u een verzoek wilt indienen om één of meer van de hierboven vermelde rechten uit te oefenen, kunt u een verzoek sturen naar VLAM vzw – afd. Multimedia, Koning Albert II-laan 15 bus 411, 1210 Brussel (postadres) of via e-mail met in bijlage dit verzoek sturen naar <a href="mailto:multimedia@vlam.be" class="spamspan">multimedia@vlam.be</a>. In dit verzoek moet duidelijk worden vermeld welk recht u wenst uit te oefenen en waarom. Het moet ook gedateerd en ondertekend zijn en vergezeld gaan van een digitaal gescande kopie van uw geldige identiteitskaart waaruit uw identiteit blijkt. Wij zullen u binnen een redelijke termijn op de hoogte stellen van de ontvangst van dergelijk verzoek. Indien de aanvraag gegrond blijkt te zijn, zullen wij deze zo spoedig als redelijkerwijs mogelijk is en uiterlijk dertig (30) dagen na ontvangst van de aanvraag inwilligen zonder verder tegenbericht. Indien de aanvraag ongegrond zou blijken te zijn, zullen wij u op de hoogte brengen van de weigering en de reden er van.</li>
                    <li>Als u een klacht heeft over de verwerking van uw persoonsgegevens door ons, kunt u altijd contact met ons opnemen via het e-mailadres <a href="mailto:juridischedienst@vlam.be" class="spamspan">juridischedienst@vlam.be</a>. Blijft u ontevreden over ons antwoord, dan staat het u vrij om een klacht in te dienen bij de Vlaamse Ombudsdienst en/of de bevoegde gegevensbeschermingsautoriteit, de Belgische Privacycommissie. Ga voor meer in</li>
                </ul>
            </div>
        </div>
    )
}