import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header/Header";
import Button from "../components/Button/Button";
import ImageComp from "../components/ImageComp/ImageComp";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLocation, useNavigate } from "react-router-dom";
import { useWindowResize } from "../hooks/useWindowResize";
import { useMyContext } from "../state/Context";
import LightBox from "../components/LightBox/LightBox";
import SocialShare from "../components/SocialShare/SocialShare";
import DisclaimerFooter from "../components/DisclaimerFooter/DisclaimerFooter"
import { getCookieConsentValue } from "react-cookie-consent";
import './Results.scss';
import 'swiper/css';

// const downloadImages = (imageLinks) => {
//     imageLinks.forEach((link, index) => {
//         const image = new Image();
//         image.crossOrigin = 'Anonymous';
//         image.src = link;

//         image.onload = () => {
//             const canvas = document.createElement('canvas');
//             const context = canvas.getContext('2d');

//             // Set canvas dimensions to the image dimensions
//             canvas.width = image.width;
//             canvas.height = image.height;

//             // Draw the image on the canvas
//             context.drawImage(image, 0, 0);

//             // Convert the canvas content to a data URL
//             const dataURL = canvas.toDataURL('image/png');

//             // Create a download link
//             const a = document.createElement('a');
//             a.href = dataURL;
//             a.download = `image_${index + 1}.png`;

//             // Append the link to the body and trigger the download
//             document.body.appendChild(a);
//             a.click();

//             // Remove the link from the body
//             document.body.removeChild(a);
//         };
//     });
// };

const pushToDataLayer = (type, data) => {
    const events = {
        zipcode: {
            event: "enter_zipcode",
            zipcode: data
        },
        restart: {
            event: "try_again"
        },
        download: {
            event: "download_picture"
        },
        share: {
            event: "share"
        },
    }
    window.dataLayer.push(events[type]);
}

const downloadImages = (imageLinks) => {
    imageLinks.forEach((imgLink) => {
        if (imgLink && imgLink.image) {
            fetch(imgLink.image, {
                method: "GET",
                headers: {}
            })
                .then(response => {
                    response.arrayBuffer().then(function (buffer) {
                        const url = window.URL.createObjectURL(new Blob([buffer]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `het_tuinainemer_effect_${imgLink.index + 1}.png`); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    });
                })
                .catch(err => {
                    console.log(err);
                });
        }
    });
};

export default function Results() {
    const { state } = useLocation();
    const [generated, setGenerated] = useState();
    const [postCodeError, setPostCodeError] = useState(false);
    const [postCode, setPostCode] = useState(null)
    const { windowWidth } = useWindowResize();
    const { appState, dispatch } = useMyContext();
    const searchWrapper = useRef()
    const navigate = useNavigate();
    const [lightBox, setLightBox] = useState({ active: false, selectedImage: null });

    useEffect(() => {
        setGenerated(appState.creation?.creationImages)
    }, [appState])

    //on refresh init datalayer
    useEffect(() => {
        if (!!getCookieConsentValue('CookieConsent')) {
            window.dataLayer && window.dataLayer.push({
                event: 'result page'
            })
        }
    }, [])

    useEffect(() => {
        dispatch({
            type: 'SET_ANIME_PLAYSTATE',
            payload: false,
        });

        if (!state) {
            navigate('/')
        } else {
            const allCreationsUrl = state.creationFiles.map((gen) => {
                return process.env.REACT_APP_BASE_URL + gen.file.filePath
            })
            dispatch({
                type: 'SET_CREATION',
                payload: {
                    creationId: state.id,
                    creationImages: state.creationFiles
                },
            });
            dispatch({
                type: 'SET_FAVORITE',
                payload: allCreationsUrl,
            });
        }
    }, [state, navigate, dispatch])

    const savePictures = (image, index) => {
        const imageLink = [{
            image: image,
            index: index
        }]
        downloadImages(imageLink)
        !!getCookieConsentValue("CookieConsent") && pushToDataLayer('download', null)
    }

    useEffect(() => {
        const { downloadImage } = appState
        if (downloadImage.download && downloadImage.image) {
            downloadImages([downloadImage])
            if (!!getCookieConsentValue("CookieConsent")) {
                pushToDataLayer('download', null)
            }
        }
    }, [appState])

    const handlePostcodeSearch = (e) => {
        // const postCodeRegEx = /^([1-9]\d{3}|[1-9]\d{2}[0-8]\d{1}|999[0-2])$/;
        const postCodeRegEx = /^(?:(?:[1-9])(?:\d{3}))$/;

        const postCode = e.target.value;

        if (postCodeRegEx.test(postCode)) {
            setPostCodeError(false)
            setPostCode(e.target.value)
        } else {
            setPostCodeError(true)
        }

        handlePostcodeSubmit(e)
    }

    const handleForward = (postcode) => {
        const url = `https://tuinaannemer.be/vind-een-tuinaannemer?address_center[geocoder][geolocation_geocoder_address]=${postcode}&field_locatie_latlong_proximity=10&address=10`
        window.open(url, '_blank');
    }

    const handlePostcodeSubmit = (event) => {
        if (!postCodeError && event && event.key === 'Enter' && postCode) {
            handleForward(postCode)
            !!getCookieConsentValue('CookieConsent') && pushToDataLayer('zipcode', postCode)
        }

        if (!postCodeError && event && event === 'submit' && postCode) {
            handleForward(postCode)
            !!getCookieConsentValue('CookieConsent') && pushToDataLayer('zipcode', postCode)
        }
    }

    const handleLightbox = (image, index) => {
        dispatch({
            type: 'SET_LIGHTBOX',
            payload: 'open',
        });

        dispatch({
            type: 'SET_DOWNLOAD_IMAGE',
            payload: {
                image: image,
                index: index,
                download: false
            },
        });

        setLightBox({ active: true, selectedImage: image, index: index })
    }

    const reselectStyle = () => {
        !!getCookieConsentValue('CookieConsent') && pushToDataLayer('restart', null)
        navigate('/selecteer-je-stijl', { state: { id: state.initFile.id } });
    }

    const handleInputFocus = (type) => {
        type === 'focus' ? searchWrapper.current?.classList.add('focus') : searchWrapper.current?.classList.remove('focus')
    }

    const calculateSlidesPerView = () => {
        if (windowWidth > 768) {
            return 2.2
        } else {
            return 1.2
        }
    }

    const handleModal = () => {

        // Check if the Web Share API is supported
        if (navigator.share) {
            // Define the data to be shared
            const shareData = {
                title: 'Ontdek wat jouw tuin allemaal kan worden!',
                text: 'Ontdek alles wat je tuin zou kunnen zijn met slechts een druk op de knop. Met behulp van AI toveren we jouw tuin om in een op maat gemaakte oase.',
                url: 'https://staging.tuinainemer.be',
            };

            // Trigger the native share dialog
            navigator.share(shareData)
                .then(() => console.log('Shared successfully'))
                .catch((error) => console.error('Error sharing:', error));
        } else {
            dispatch({
                type: 'SET_SHAREMODAL',
                payload: 'open',
            });
        }
        // !!getCookieConsentValue('CookieConsent') && pushToDataLayer('share', null)
    }


    return (
        <>
            <div className="page-wrapper results">
                <Header />
                <div className="flex-grow items-start  lg:gap-10 grid grid-cols-12 max-w-screen-2xl mx-auto p-0 lg:p-5 space-y-8">
                    <div className="col-span-12 px-5 pb-5 pt-10 lg:pt-0 order-1 block lg:hidden">
                        <h1 className="title">Neem dit mee naar <span className="highlight">je tuinaannemer</span></h1>
                    </div>
                    <div className="col-span-12 lg:space-y-10 order-3 lg:order-2 lg:col-span-5 xl:col-span-5 pb-10 p-5">
                        <div className="cta-text space-y-8">
                            <h1 className="title hidden lg:block">Neem dit mee naar <span className="highlight">je tuinaannemer</span></h1>
                            <p className="hidden lg:block">Heb je al zicht op jouw droomtuin? Neem dan nu contact op met een tuinaannemer bij jou in de buurt en maak van jouw digitale droom een realiteit.</p>
                        </div>
                        <div className="search-comp bg-green-800 p-5 py-14 space-y-5">
                            <h3>Vind een tuinaannemer in je buurt</h3>
                            <div ref={searchWrapper} className="search flex">
                                <input onBlur={() => handleInputFocus('blur')} onFocus={() => handleInputFocus('focus')} type="number" maxLength="4" onKeyDown={handlePostcodeSubmit} onChange={(e) => handlePostcodeSearch(e)} className="w-full p-3" placeholder="wat is je postcode?"></input>
                                <Button handleClick={() => handlePostcodeSubmit('submit')} type={'solid'} text="zoeken" />
                            </div>
                        </div>
                        <div className="button-restart flex flex-col xl:flex-row gap-2 pt-5 lg:pt-0">
                            <Button classNames={'w-full'} handleClick={handleModal} type={'solid'} text="Delen" iconRight={'share'} />
                            <Button classNames={'w-full'} handleClick={reselectStyle} type={'outline'} text="Probeer opnieuw" iconRight={'restart'} />
                        </div>
                        <p className="p-5 block lg:hidden">Heb je al zicht op jouw droomtuin? Neem dan nu contact op met een tuinaannemer bij jou in de buurt en maak van jouw digitale droom een realiteit.</p>
                        <p className="disclaimer px-5 lg:px-0 block">Deze inhoud is gegenereerd door kunstmatige intelligentie. Het kan fouten of onnauwkeurigheden bevatten en mag niet worden gebruikt ter vervanging van professioneel advies.</p>
                    </div>

                    <div className="col-span-12 order-2 pl-5 lg:p-0 lg:order-3 lg:col-span-7 xl:col-span-6 xl:col-start-8">
                        {windowWidth > 1024 ?
                            <div className="grid grid-cols-8 gap-4">
                                {generated && generated.map((gen, i) => (
                                    <div key={i} className="col-span-8 md:col-span-4 xl:col-span-4">
                                        <ImageComp download handleLightbox={handleLightbox} firstImage={`${process.env.REACT_APP_BASE_URL}${gen.file.filePath}`} index={i} />
                                    </div>
                                ))}
                            </div>
                            :
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={calculateSlidesPerView()}
                            >
                                {generated && generated.map((gen, i) => (
                                    <SwiperSlide key={i}>
                                        <ImageComp download handleLightbox={handleLightbox} firstImage={`${process.env.REACT_APP_BASE_URL}${gen.file.filePath}`} index={i} />
                                    </SwiperSlide>

                                ))}
                            </Swiper>
                        }
                    </div>
                </div>
                <DisclaimerFooter />
            </div>
            {appState.lightBoxState === 'open' &&
                <LightBox initImage={state.initFile} handleSavePictures={savePictures} image={lightBox.selectedImage} index={lightBox.index} generated={generated} />
            }
            <SocialShare />
        </>
    )
}