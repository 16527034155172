import React, { useEffect, useState, useLayoutEffect } from 'react';
import logo from '../../images/ainnemer_logo.svg';
import "@lottiefiles/lottie-player";
import logoAnim from '../../images/logo_anim.json';
import './Header.scss';
import { useNavigate, useLocation } from "react-router-dom";

export default function Header() {
    const [logoAnime, setLogoAnime] = useState()
    const navigate = useNavigate();
    const location = useLocation();
    const [isHomepage, setIsHomePage] = useState();

    useLayoutEffect(() => {
        const isHomepage = location.pathname === '/'
        setIsHomePage(isHomepage)
    }, [location.pathname])

    useEffect(() => {
        setLogoAnime(JSON.stringify(logoAnim))
    }, [])

    return (
        <header className='header flex justify-center px-5 pt-5'>
            {isHomepage ?
                <div className='logo-anime'>
                    {logoAnime &&
                        <lottie-player
                            autoplay
                            speed="0.5"
                            mode="normal"
                            src={logoAnime}
                            style={{ width: "250px" }}
                        >
                        </lottie-player>
                    }
                </div> :
                <img onClick={(() => navigate('/'))} src={logo} className="app-logo cursor-pointer" alt="logo" />
            }
        </header>
    )
}