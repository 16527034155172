import React from 'react';
import './DisclaimerFooter.scss';
import DisclaimerText from './DisclaimerText';

export default function DisclaimerFooter({ dark, displayDisclaimerText = null }) {
    return (
        <div className={`disclaimer--footer ${dark ? 'dark' : 'light'}`}>
            { displayDisclaimerText &&  <DisclaimerText /> }
            <div className="links-wrapper">
                <a href="/privacy">Privacy policy</a>
                -
                <a href="/cookie">Cookie policy</a>
            </div>
        </div>
    )
}