import React from 'react';
import './ImageComp.scss';
// import Favourite from './Favourite';
import Download from './Download';
import Share from './Share';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';

export default function ImageComp({ firstImage, secondImage, download, handleLightbox, index, selectedImages, share }) {
   
    const handleClick = (e) => {
        if (!e.target.classList.contains('icon')) {
            if (handleLightbox) {
                handleLightbox(firstImage, index)
            }
        }
    }

    const FIRST_IMAGE = {
        imageUrl: firstImage
    };
    const SECOND_IMAGE = {
        imageUrl: secondImage
    };

    return (
        <section onClick={(e) => handleClick(e)} className="image--component w-full">
            {download || share ?
                <div className='icon-wrapper'>
                    {/* {favourite &&
                        <Favourite image={secondImage ? secondImage : firstImage} />
                         } */}
                    {download &&
                        <Download index={index} image={secondImage ? secondImage : firstImage} />
                    }
                    {share &&
                        <Share index={index} image={secondImage ? secondImage : firstImage} />
                    }
                </div> : null
            }
            {secondImage ?
                <ReactBeforeSliderComponent
                    firstImage={SECOND_IMAGE}
                    secondImage={FIRST_IMAGE}
                />
                :
                <img alt="generated ai" src={firstImage} />
            }
        </section>
    )
}