import { useEffect } from "react"
import Button from "../components/Button/Button"
import Header from "../components/Header/Header"
import ImageComp from "../components/ImageComp/ImageComp"
import firstImage from '../images/landing_firstImage.jpg'
import secondImage from '../images/landing_secondImage.jpg'
import './Landing.scss';
import { useMyContext } from "../state/Context"
import DisclaimerFooter from "../components/DisclaimerFooter/DisclaimerFooter"
import SouthIcon from '@mui/icons-material/South';
import { useNavigate } from "react-router-dom";
import { getCookieConsentValue } from "react-cookie-consent"

export default function Landing() {
    const { dispatch } = useMyContext();
    const navigate = useNavigate();

    //on refresh init datalayer
    useEffect(() => {
        if (!!getCookieConsentValue('CookieConsent')) {
            window.dataLayer && window.dataLayer.push({
                event: 'landing page'
            })
        }
    }, [])

    useEffect(() => {
        dispatch({
            type: 'SET_ANIME_PLAYSTATE',
            payload: true,
        });
    }, [dispatch])

    const handleDataLayer = (buttonPos) => {
        !!getCookieConsentValue("CookieConsent") && window.dataLayer.push({
            event: 'add_photo',
            position: buttonPos
        });
        navigate('/upload');
    }

    return (
        <div className="page-wrapper">
            <Header />
            <section className='flex-grow flex items-center landing--intro space-y-10 p-5 pb-0 pt-10'>
                <div className='grid grid-cols-12 space-y-8 max-w-screen-2xl mx-auto'>
                    <div className='col-span-12 lg:col-span-8 lg:col-start-3 space-y-3'>
                        <h1 className='title sm:text-center'>
                            Ontdek wat jouw tuin <br /><span className='highlight'>allemaal kan worden!</span>
                        </h1>
                        <p className='intro-text pb-10 sm:text-center md:max-w-xl'>
                            Ontdek alles wat je tuin zou kunnen zijn met slechts een druk op de knop. Met behulp van AI toveren we jouw tuin om in een op maat gemaakte oase.
                        </p>
                        <div className="button-wrapper sm:flex sm:justify-center">
                            <Button handleClick={() => handleDataLayer('1')} position={'center'} text={'Voeg je foto toe'} type={'solid'} iconRight={'arrow-right'} />
                        </div>
                        <div className="flex justify-center pt-20">
                            <a href="#description" className="scroll">scroll <SouthIcon /></a>
                        </div>
                    </div>
                </div>
            </section>
            <section className='flex-grow flex items-center landing--description pt-10'>
                <div id="description" className="bottom-backdrop"></div>
                <div className='grid grid-cols-12 md:gap-10 p-5 space-y-8 md:space-y-0 max-w-screen-lg mx-auto'>
                    <div className='col-span-12 md:col-span-5 '>
                        <ImageComp firstImage={firstImage} secondImage={secondImage} />
                    </div>
                    <div className='col-span-12 md:col-span-7 space-y-3'>
                        <h2 className='title'>
                            Praktische voedseltuin of rustige oase:  <span className='highlight'>Met AI tonen we jouw droomtuin!</span>
                        </h2>
                        <p className='desc-text pb-10'>
                            Upload een foto van jouw huidige tuin, en selecteer de stijl waar je voor wilt gaan: fleurige bloementuin, serene waterpartij, praktische voedseltuin,... Vervolgens toont onze AI-tool wat jouw tuin allemaal kan worden.
                        </p>
                        <Button handleClick={() => handleDataLayer('2')} maxContent text={'Voeg je foto toe'} type={'solid'} iconRight={'arrow-right'} />
                    </div>
                    <div className="col-span-12 pt-10">
                        <DisclaimerFooter dark displayDisclaimerText />
                    </div>
                </div>
            </section>
        </div>
    )
}