import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { MyProvider } from './state/Context';
import {
  createBrowserRouter, RouterProvider, Navigate
} from "react-router-dom";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

import './scss/index.scss';
import App from './App';
import Upload from './views/Upload';
import Style from './views/Style';
import Results from './views/Results';
import reportWebVitals from './reportWebVitals';
import MsPlay from './components/MarchingSQ2/msPlay';
import Disclaimer from './views/Disclaimer';
import TagManager from 'react-gtm-module'

const RootComponent = () => {
  const [consent, setConsent] = useState(false)

  useEffect(() => {
    if (!!getCookieConsentValue('CookieConsent') && !window.dataLayer) {
      TagManager.initialize({
        gtmId: process.env.REACT_APP_G_TAG
      })
    }
  }, [consent])


  const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
    },
    {
      path: "/upload",
      element: <Upload />,
    },
    {
      path: "/selecteer-je-stijl",
      element: <Style />,
    },
    {
      path: "/resultaat",
      element: <Results />,
    },
    {
      path: "*",
      element: <Navigate to="/" replace />,
    },
    {
      path: "/privacy",
      element: <Disclaimer type="privacy" />,
    },
    {
      path: "/cookie",
      element: <Disclaimer type="cookie" />,
    }
  ]);

  return (
    <React.StrictMode>
      <MyProvider consent={consent}>
        <CookieConsent
          enableDeclineButton
          onAccept={() => {
            setConsent(true)
          }}
          onDecline={() => {
          }}
          declineButtonStyle={{
            background: 'rgba(0,0,0,0)',
            border: "1px solid #5dba3a",
            color: "#5dba3a",
            padding: "0.5rem 1rem",
            borderRadius: "5px",
            fontSize: "1.2rem"
          }}
          declineButtonText="Weigeren"
          style={{ background: "#046a38", color: '#5dba3a', fontSize: "1.2rem", padding: "1rem 0" }}
          buttonStyle={{ background: "#5dba3a", color: "white", padding: "0.5rem 1rem", borderRadius: "5px", fontSize: "1.2rem" }}
          buttonText="Accepteren"
        >Deze website maakt gebruik van cookies om de gebruikerservaring te verbeteren.</CookieConsent>
        <RouterProvider router={router} />
        <MsPlay secondary play={false} />
      </MyProvider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
