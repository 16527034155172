import React, { useState, useMemo, useEffect } from "react";
import { makeNoise3D } from "open-simplex-noise";
import { ReactP5Wrapper } from "@p5-wrapper/react";

const MsPlay = ({play, secondary}) => {
    const [animePlay] = useState(play)
    const [rez, setRez] = useState(40)
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    let cols, rows;

    let field = useMemo(() => {
        return Array.from({ length: cols }, () => Array(rows).fill(1));
    }, [cols, rows]);

    let increment = 0.05;

    let noise = makeNoise3D(Date.now())

    cols = Math.ceil(windowSize.width / rez);
    rows = Math.ceil(windowSize.height / rez);

    // Recreate the field array based on the new cols and rows
    field = Array.from({ length: cols }, () => Array(rows).fill(1));

    function drawLine(p5, v1, v2) {
        p5.line(v1.x, v1.y, v2.x, v2.y);
    }

    function getState(a, b, c, d) {
        return (a > 0 ? 8 : 0) + (b > 0 ? 4 : 0) + (c > 0 ? 2 : 0) + (d > 0 ? 1 : 0);
    }

    const sketch = useMemo(() => {
        let zoff = 0;
        const createVectorCache = (p5, cols, rows) => {
            const vectorCache = Array.from({ length: cols }, () => Array(rows).fill(null));

            for (let i = 0; i < cols; i++) {
                for (let j = 0; j < rows; j++) {
                    vectorCache[i][j] = p5.createVector();
                }
            }

            return vectorCache;
        };

        return (p5) => {
            const vectorCache = createVectorCache(p5, cols, rows);
            p5.setup = () => p5.createCanvas(windowSize.width, windowSize.height, p5.WEBGL);
            p5.draw = () => {
                p5.background('#f2f4ee');
                p5.translate(-windowSize.width / 2, -windowSize.height / 2); // Center the drawing
                p5.stroke('#d6e4cc');
                p5.strokeWeight(2.4);

                let xoff = 0;
                for (let i = 0; i < cols; i++) {
                    xoff += increment;
                    let yoff = 0;
                    for (let j = 0; j < rows; j++) {
                        field[i][j] = p5.float(noise(xoff, yoff, zoff));
                        yoff += increment;
                    }
                }

                //adjust the speed
                zoff += 0.0007;


                for (let h = -1; h < 1; h += 0.15) {
                    for (let i = 0; i < cols - 1; i++) {
                        for (let j = 0; j < rows - 1; j++) {
                            let f0 = field[i][j] - h;
                            let f1 = field[i + 1][j] - h;
                            let f2 = field[i + 1][j + 1] - h;
                            let f3 = field[i][j + 1] - h;

                            let x = i * rez;
                            let y = j * rez;

                            let a_val = f0 + 1;
                            let b_val = f1 + 1;
                            let c_val = f2 + 1;
                            let d_val = f3 + 1;

                            let a = vectorCache[i][j];
                            let b = vectorCache[i + 1][j];
                            let c = vectorCache[i + 1][j + 1];
                            let d = vectorCache[i][j + 1];

                            // let a = p5.createVector();
                            let amt = (1 - a_val) / (b_val - a_val);
                            a.x = p5.lerp(x, x + rez, amt);
                            a.y = y;

                            // let b = p5.createVector();
                            amt = (1 - b_val) / (c_val - b_val);
                            b.x = x + rez;
                            b.y = p5.lerp(y, y + rez, amt);

                            // let c = p5.createVector();
                            amt = (1 - d_val) / (c_val - d_val);
                            c.x = p5.lerp(x, x + rez, amt);
                            c.y = y + rez;

                            // let d = p5.createVector();
                            amt = (1 - a_val) / (d_val - a_val);
                            d.x = x;
                            d.y = p5.lerp(y, y + rez, amt);

                            let state = getState(f0, f1, f2, f3);
                            switch (state) {
                                case 1:
                                    drawLine(p5, c, d);
                                    // p5.line(c.x, c.y, d.x, d.y);
                                    break;
                                case 2:
                                    drawLine(p5, b, c);
                                    // p5.line(b.x, b.y, c.x, c.y);
                                    break;
                                case 3:
                                    drawLine(p5, b, d);
                                    // p5.line(b.x, b.y, d.x, d.y);
                                    break;
                                case 4:
                                    drawLine(p5, a, b);
                                    // p5.line(a.x, a.y, b.x, b.y);
                                    break;
                                case 5:
                                    drawLine(p5, a, d);
                                    drawLine(p5, b, c);
                                    // p5.line(a.x, a.y, d.x, d.y);
                                    // p5.line(b.x, b.y, c.x, c.y);
                                    break;
                                case 6:
                                    drawLine(p5, a, c);
                                    // p5.line(a.x, a.y, c.x, c.y);
                                    break;
                                case 7:
                                    drawLine(p5, a, d);
                                    // p5.line(a.x, a.y, d.x, d.y);
                                    break;
                                case 8:
                                    drawLine(p5, a, d);
                                    // p5.line(a.x, a.y, d.x, d.y);
                                    break;
                                case 9:
                                    drawLine(p5, a, c);
                                    // p5.line(a.x, a.y, c.x, c.y);
                                    break;
                                case 10:
                                    drawLine(p5, a, b);
                                    drawLine(p5, c, d);
                                    // p5.line(a.x, a.y, b.x, b.y);
                                    // p5.line(c.x, c.y, d.x, d.y);
                                    break;
                                case 11:
                                    drawLine(p5, a, b);
                                    // p5.line(a.x, a.y, b.x, b.y);
                                    break;
                                case 12:
                                    drawLine(p5, b, d);
                                    // p5.line(b.x, b.y, d.x, d.y);
                                    break;
                                case 13:
                                    drawLine(p5, b, c);
                                    // p5.line(b.x, b.y, c.x, c.y);
                                    break;
                                case 14:
                                    drawLine(p5, c, d);
                                    // p5.line(c.x, c.y, d.x, d.y);
                                    break;
                                default:
                                    break;
                            }
                        }

                    }
                }
                !animePlay ? p5.noLoop() : p5.loop();
                p5.frameRate(20)
            };

        }
    }, [windowSize, cols, rows, rez, increment, field, noise, animePlay]);

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        animePlay ? setRez(40) : setRez(15)
    }, [animePlay])

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className={`square-switches ${secondary ? '-z-20' : '-z-10'}`}>
            <ReactP5Wrapper sketch={sketch} />
        </section>
    );
};

export default MsPlay;
