import React from "react";
import Header from "../components/Header/Header";
import Button from "../components/Button/Button";
import Cookie from "../components/Legal/Cookie";
import Privacy from "../components/Legal/Privacy";
import './Disclaimer.scss';


export default function Disclaimer({ type }) {

    const legal = {
        privacy: <Privacy />,
        cookie: <Cookie />,
    }

    return (
        <div className="page-wrapper disclaimer">
            <Header />
            <div className="flex-grow items-start lg:gap-10 grid grid-cols-12 max-w-screen-lg mx-auto p-0 lg:p-5 space-y-8">
                <div className="col-span-12 py-24 px-5">
                    {legal[type]}
                    <div className="mt-10 flex">
                        <Button path={'/'} type={'solid'} text="Terug naar startpagina" />
                    </div>
                </div>
            </div>
        </div>
    )
}