import React, { useEffect, useCallback } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import Button from "../Button/Button";
import ImageComp from "../ImageComp/ImageComp";
import CancelIcon from '@mui/icons-material/Cancel';
import { useMyContext } from "../../state/Context";
import 'swiper/css';
import './LightBox.scss';


export default function LightBox({ initImage, generated, image, index, handleSavePictures }) {
    const { dispatch } = useMyContext()

    const closeLightBox = useCallback(() => {
        dispatch({
            type: 'SET_LIGHTBOX',
            payload: 'closed',
        });
    }, [dispatch])

    const handlePictures = () => {
        handleSavePictures(image, index)
    }

    useEffect(() => {
        const closeLb = (e) => {
            if (e.keyCode === 27) {
                closeLightBox()
            }
            if (!e.target.closest('.swiper-wrapper') && !e.target.closest('.button-wrapper')) {
                closeLightBox()
            }
        }
        document.addEventListener('keyup', closeLb);
        document.addEventListener('mousedown', closeLb)

        return () => {
            document.removeEventListener('keyup', closeLb)
            document.removeEventListener('mousedown', closeLb)
        }
    }, [closeLightBox])

    return (
        <div className="lightbox--component">
            <div onClick={closeLightBox} className="close"><CancelIcon /></div>
            <div className="lightbox--inner mx-auto">
                <div className="swiper--big">
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        allowTouchMove={false}
                        onSwiper={(swiper) => {
                            swiper.slideTo(index, 0)
                        }}
                    >
                        {generated && generated.map((gen, i) => (
                            <SwiperSlide key={i}>
                                <ImageComp favourite firstImage={`${process.env.REACT_APP_BASE_URL}${initImage.filePath}`} secondImage={`${process.env.REACT_APP_BASE_URL}${gen.file.filePath}`} index={i} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                {/* <div className="swiper--thumb">
                    <Swiper
                        modules={[Thumbs]}
                        spaceBetween={15}
                        watchSlidesProgress
                        slidesPerView={3.5}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={setThumbsSwiper}
                    >
                        {generated && generated.map((gen, i) => (
                            <SwiperSlide>
                                <ImageComp firstImage={`${process.env.REACT_APP_BASE_URL}${gen.file.filePath}`} index={i} />
                            </SwiperSlide>

                        ))}
                    </Swiper>
                </div> */}
                <div className="button-wrapper mt-5 ">
                    <Button handleClick={handlePictures} text="Download" type={'solid'} iconRight={'download'} />
                </div>
            </div>
        </div>
    )
}