import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../components/Header/Header";
import GaderdenStyle from "../components/GaderdenStyle/GaderdenStyle";
import { useMyContext } from "../state/Context";
import Button from "../components/Button/Button";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import { useWindowResize } from "../hooks/useWindowResize";
import { getCookieConsentValue } from "react-cookie-consent";

import bloementuin from '../images/illustrations/bloementuin.jpg';
import ecotuin from '../images/illustrations/ecotuin.jpg';
import meditteraans from '../images/illustrations/meditteraans.jpg';
import modern from '../images/illustrations/modern.jpg';
import prairietuin from '../images/illustrations/prairietuin.jpg';
import voedseltuin from '../images/illustrations/voedseltuin.jpg';
import avonturentuin from '../images/illustrations/avonturentuin.jpg';
import waterpartij from '../images/illustrations/waterpartij.jpg';
import DisclaimerFooter from "../components/DisclaimerFooter/DisclaimerFooter"

import './Style.scss';

const gardenStyles = [
    {
        image: bloementuin,
        name: 'Bloementuin',
        choice: 'flower'
    },
    {
        image: ecotuin,
        name: 'Ecotuin',
        choice: 'eco'
    },
    {
        image: meditteraans,
        name: 'Mediterrane tuin',
        choice: 'medi'
    },
    {
        image: modern,
        name: 'Modern',
        choice: 'modern'
    },
    {
        image: prairietuin,
        name: 'Prairietuin',
        choice: 'prairie'
    },
    {
        image: voedseltuin,
        name: 'Voedseltuin',
        choice: 'food'
    },
    {
        image: avonturentuin,
        name: 'Avonturentuin',
        choice: 'adventure'
    },
    {
        image: waterpartij,
        name: 'Waterpartij',
        choice: 'water'
    }
]

export default function Style() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [gardenStyle, setGardenStyle] = useState(null)
    const [error, setError] = useState(true)
    const [loading, setLoading] = useState();
    const [initId, setInitId] = useState()
    const { windowWidth, breakpoint } = useWindowResize();
    const containerRef = useRef();
    const buttonsRef = useRef();
    const { dispatch } = useMyContext();
    const [abortController, setAbortController] = useState(null)

    useEffect(() => {
        return () => {
            if (abortController) {
                abortController.abort()
            }
        }
    }, [abortController])

    //on refresh init datalayer
    useEffect(() => {
        if (!!getCookieConsentValue('CookieConsent')) {
            window.dataLayer && window.dataLayer.push({
                event: 'select style page'
            })
        }
    }, [])

    const pushToDataLayer = () => {
        !!getCookieConsentValue("CookieConsent") && window.dataLayer.push({
            event: 'select_style',
            style: gardenStyle.name
        });
    }

    const createAiPhoto = (style, controller) => {
        setLoading(true);

        const requestData = {
            style: style,
            init_file: initId,
            privacy: true,
            public_use: true,
        };

        const createPhotoRequest = () => {
            const data = JSON.stringify(requestData);

            const requestOptions = {
                method: 'POST',
                body: data,
                redirect: 'follow',
                signal: controller.signal
            };

            fetch(`${process.env.REACT_APP_BASE_URL}/api/creations`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    getCreations(data.creation.id)
                })
                .catch((error) => {
                    setLoading(false);
                    console.error('Error creating photo', error);
                });
        };

        createPhotoRequest();
    };

    const getCreations = (id) => {

        let timeoutId;

        const getCreationRequest = () => {
            fetch(`${process.env.REACT_APP_BASE_URL}/api/creations/${id}`, {
                method: 'GET',
                redirect: 'follow'
            })
                .then(response => response.json())
                .then(result => {
                    if (result.status === 'processed') {
                        navigate('/resultaat', { state: result });
                    } else {
                        timeoutId = setTimeout(getCreationRequest, 2000);
                    }
                })
                .catch(error => {
                    setLoading(false);
                });
        };

        getCreationRequest();

        const clearTimeoutAndCleanup = () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };

        return clearTimeoutAndCleanup;
    };

    const handleStyleSelect = (selectedGarden) => {
        setGardenStyle(selectedGarden)
        setError(false)
    }

    const handleCreateAi = () => {
        const controller = new AbortController();
        setAbortController(controller)

        if (!gardenStyle) {
            setError(true)
        }

        if (!error) {
            //prod
            createAiPhoto(gardenStyle.choice, controller)
            pushToDataLayer(gardenStyle.name)
        }
    }

    useEffect(() => {
        if (!state) {
            navigate('/')
        } else {
            setInitId(state.id)
        }
    }, [state, breakpoint, navigate])

    useEffect(() => {
        if (windowWidth < 640 && buttonsRef.current && containerRef.current) {
            const buttonsHeight = buttonsRef.current.getBoundingClientRect().height
            containerRef.current.style.paddingBottom = `${buttonsHeight}px`
        } else if (containerRef.current) {
            containerRef.current.style.paddingBottom = `0px`
        }
    }, [windowWidth])

    useEffect(() => {
        dispatch({
            type: 'SET_ANIME_PLAYSTATE',
            payload: false,
        });
    }, [dispatch])

    return (
        <div className="page-wrapper">
            <Header />
            {/* <LoadingScreen style={{choice: 'medi', name: 'Mediteraanse tuin', index: 2}} /> */}
            {loading ?
                <LoadingScreen style={gardenStyle} />
                :
                <div ref={containerRef} className="style-option-container space-y-2 p-5">
                    <div className="grid grid-cols-12 space-y-5">
                        <div className="col-span-12 md:col-span-6 md:col-start-4">
                            <h1>Kies je <span className="highlight">gewenste stijl</span></h1>
                        </div>
                        <div className="style--wrapper col-span-12 md:col-span-6 md:col-start-4 lg:col-span-8 lg:col-start-3 2xl:col-span-6 2xl:col-start-4">
                            <div className="grid grid-cols-12 gap-3">
                                {gardenStyles.map((garden, i) => (
                                    <div key={i} className="col-span-6 lg:col-span-3">
                                        <GaderdenStyle handeClick={handleStyleSelect} index={i} selected={gardenStyle && gardenStyle.index === i ? true : false} name={garden.name} choice={garden.choice} image={garden.image} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div ref={buttonsRef} className="buttons flex justify-center gap-3">
                        <Button maxContent text={'Vorige stap'} path={'/upload'} type={'outline'} />
                        <Button classNames={'proceed'} disabled={error ? true : false} maxContent handleClick={handleCreateAi} text={'Zet ons aan de slag'} type={'solid'} iconRight={'arrow-right'} />
                    </div>
                    <DisclaimerFooter />
                </div>
            }
        </div>
    )
}