import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header/Header"
import ImageComp from "../components/ImageComp/ImageComp"
import Button from "../components/Button/Button"
import { useNavigate } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { useMyContext } from "../state/Context";
import DisclaimerFooter from "../components/DisclaimerFooter/DisclaimerFooter";
import { getCookieConsentValue } from "react-cookie-consent";


import './Upload.scss';

export default function Upload() {
    const navigate = useNavigate();
    const [photo, setPhoto] = useState()
    const [file, setFile] = useState()
    const uploadInnerRef = useRef()
    const fileInput = useRef()
    const progressBarRef = useRef()
    const [uploadProgress, setUploadProgress] = useState(0)
    const [isUploading, setIsUploading] = useState(false)
    const { dispatch } = useMyContext();

    //on refresh init datalayer
    useEffect(() => {
        if (!!getCookieConsentValue('CookieConsent')) {
            window.dataLayer && window.dataLayer.push({
                event: 'upload page'
            })
        }
    }, [])

    useEffect(() => {
        if (photo && uploadProgress) {
            const progressBar = progressBarRef.current
            if (progressBar) {
                const bar = progressBar.querySelector('.front-bar');
                const percentage = progressBar.querySelector('.percentage');
                percentage.innerHTML = `${Math.floor(uploadProgress)}%`
                bar.style.width = `${uploadProgress}%`
            }
        }
    }, [isUploading, uploadProgress, photo])

    const handleClick = () => {
        fileInput.current.click()
    }

    const convertFileToPhoto = (uploadedFile) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const imageDataURL = event.target.result;
            setPhoto(imageDataURL);
        };

        reader.readAsDataURL(uploadedFile);
    }

    const handlePhotoSelect = (e) => {
        const file = e.target.files && e.target.files[0]
        if (file) {
            setFile(file)
            convertFileToPhoto(file)
        }
    }

    //request with XHR to track upload progress
    const handlePhotoUpload = () => {
        setIsUploading(true)
        let formdata = new FormData();
        formdata.append("image", file);

        const xhr = new XMLHttpRequest();

        // Track upload progress
        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const progress = (event.loaded / event.total) * 100;
                setUploadProgress(progress);
            }
        };

        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                const data = JSON.parse(xhr.responseText);
                navigate('/selecteer-je-stijl', { state: data });
                setUploadProgress(0);
            }
        };
        // xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
        xhr.open("POST", `${process.env.REACT_APP_BASE_URL}/api/file/upload`, true);
        xhr.send(formdata);
    };

    useEffect(() => {
        // Cleanup function
        return () => {
            setUploadProgress(0);
        };
    }, []);


    const handleOnDragOver = (e) => {
        e.preventDefault()
    }

    const handleOnDragEnter = () => {
        uploadInnerRef.current.classList.add('dropping')
    }

    const handleOnDragLeave = () => {
        uploadInnerRef.current.classList.remove('dropping')
    }

    const handleOnDrop = (e) => {
        e.preventDefault()
        if (e.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            [...e.dataTransfer.items].forEach((item, i) => {
                // If dropped items aren't files, reject them
                if (item.kind === "file" && item.type.includes('image') && item.type !== 'image/heic') {
                    const file = item.getAsFile();
                    setFile(file)
                    convertFileToPhoto(file)
                }
            });
        } else {
            // Use DataTransfer interface to access the file(s)
            [...e.dataTransfer.files].forEach((file, i) => {
                convertFileToPhoto(file)
            });
        }
    }

    useEffect(() => {
        dispatch({
            type: 'SET_ANIME_PLAYSTATE',
            payload: false,
        });
    }, [dispatch])


    return (
        <div className="page-wrapper">
            <section className="section--upload flex flex-col justify-stretch">
                <Header />
                <div className="upload--container h-full flex-grow space-y">
                    <input
                        onChange={(e) => handlePhotoSelect(e)}
                        ref={fileInput}
                        type="file"
                        name="media"
                        accept="image/*"
                        multiple={false}
                        style={{ display: 'none' }}
                    />
                    {!photo &&
                        <div onClick={handleClick} ref={uploadInnerRef} onDragLeave={handleOnDragLeave} onDragEnter={handleOnDragEnter} onDragOver={handleOnDragOver} onDrop={(e) => handleOnDrop(e)} className="upload--inner s col-span-12">
                            <div className="spacer"></div>
                            <div className="upload--cta space-y-5">
                                <h1 className="title">Maak een foto <br></br><span className="highlight">van je tuin</span></h1>
                                <p>Klik en selecteer of<br></br>
                                    sleep een afbeelding hierheen</p>
                            </div>
                            <div className="flex justify-center mt-10">
                                <Button text={isMobile ? 'Maak een foto' : 'Upload je foto'} type={'solid'} iconRight={isMobile ? 'camera' : 'arrow-up'} />
                            </div>
                            <div className="upload--bbgillu"></div>
                            <div className="upload--border"></div>
                        </div>
                    }
                    {photo &&
                        <div className="upload-complete pt-10 col-span-12 md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3 xl:col-span-6 xl:col-start-4">
                            <ImageComp firstImage={photo} />

                            <div className="flex relative z-50 flex-wrap gap-3 mt-10">
                                {isUploading &&
                                    <div ref={progressBarRef} className="progress-bar">
                                        <div className="percentage">
                                            0%
                                        </div>
                                        <div className="bar">
                                            <div className="front-bar"></div>
                                        </div>
                                    </div>
                                }
                                <div className="button-reupload">
                                    <Button handleClick={handleClick} text={'Upload nieuwe foto'} type={'outline'} />
                                </div>
                                <div className="button-proceed">
                                    <Button handleClick={handlePhotoUpload} text={'Ga verder'} type={'solid'} iconRight={'arrow-right'} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <DisclaimerFooter />
            </section>
        </div>
    )
}