import React from 'react';
import ShareIcon from '@mui/icons-material/Share';
import { useMyContext } from '../../state/Context';
import './Download.scss';

export default function Share({ image, index }) {
    const { appState, dispatch } = useMyContext();

    const handleDownload = () => {
        dispatch({
            type: 'SET_SHARE_IMAGE',
            payload: {
                creationId: appState.creation.creationId,
                image: appState.creation.creationImages[index],
            },
        });

        dispatch({
            type: 'SET_SHAREMODAL',
            payload: 'open',
        });
    }

    return (
        <div onClick={handleDownload} className='favourite--icon icon'>
            <ShareIcon />
        </div>
    )
}