import React from 'react';

import './Button.scss';
import EastIcon from '@mui/icons-material/East';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CachedIcon from '@mui/icons-material/Cached';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DownloadIcon from '@mui/icons-material/Download';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ShareIcon from '@mui/icons-material/Share';

import { useNavigate } from "react-router-dom";

export default function Button({ text, path, type, iconLeft, iconRight, handleClick, maxContent, position, disabled, classNames }) {
    const navigate = useNavigate();

    const icons = {
        'arrow-right': <EastIcon />,
        'arrow-down': <SouthIcon />,
        'arrow-up': <NorthIcon />,
        'camera': <CameraAltIcon />,
        'restart': <CachedIcon />,
        'heart': <FavoriteIcon />,
        'download': <DownloadIcon />,
        'facebook': <FacebookIcon />,
        'whatsapp': <WhatsAppIcon />,
        'share': <ShareIcon />
    }

    const handleButtonClick = () => {
        if (!path && handleClick) handleClick()
        if (path) navigate(path)
    }

    return (
        <div className={`button-wrapper ${maxContent && 'max-width'} ${position ? position : ''} ${disabled ? 'disabled' : ''} ${classNames ? classNames : ''}`}>
            <button onClick={handleButtonClick} className={`button_main ${iconRight ? 'gap-3' : ''} ${type}`}><span className={iconLeft ? 'icon--left flex gap-1' : ''}>{icons[iconLeft]}{text}</span> <span>{icons[iconRight]}</span></button>
        </div>
    )
}