import Landing from './views/Landing';
import MsPlay from './components/MarchingSQ2/msPlay';
import './scss/App.scss';
import './scss/config/_config.scss';

function App() {
  return (
    <>
      <div className="App space-y-10">
        <Landing />
      </div>
      <MsPlay play={false} />
    </>
  );
}

export default App;
