import './Legal.scss';
import vlamLogo from '../../images/vlam.jpg';

export default function Cookie() {
    return (
        <div className='legal'>
            <h1>Cookieverklaring</h1>
            <div class="legaltext">
                <h2>Algemeen</h2>
                <ul>
                    <li>Deze Cookieverklaring regelt het gebruik van cookies en soortgelijke technologieën (zoals Google analytics-advertentiefuncties, enz.) als een onderdeel van uw gebruik van onze Website (de “Website”) en/of alle erop aangeboden diensten of diensten die daarmee verband houden (gezamenlijk aangeduid als de “Diensten”). De verwerking van gegevens verzameld door gebruik van deze technologieën wordt uitgevoerd door het Vlaams Centrum voor Agro- en Visserijmarketing (VLAM) vzw als verantwoordelijke voor de verwerking, met zetel te 1000 Brussel aan de Simon Bolivarlaan 17 bus 411 op de 7de verdieping en met ondernemingsnummer 0454.423.323, in overeenstemming met de toepasselijke wetgeving inzake de bescherming van persoonsgegevens, dit behoudens expliciet anders aangegeven, in welk geval niet VLAM, maar een expliciet aangeduide derde de verantwoordelijke voor de verwerking is.</li>
                </ul>

                <p>In deze Cookieverklaring wordt, onder andere, aangegeven welke cookies op de website door VLAM gebruikt worden, welke gegevens op de website door VLAM worden verzameld, hoe VLAM deze gegevens gebruikt, hoe de gegevens worden beveiligd en welke uw persoonlijke rechten aangaande zijn.</p>

                <ul>
                    <li>Cookies zijn kleine tekstbestanden die op de harde schijf van een toestel worden opgeslagen en bepaalde informatie bevatten, waaronder soms ook persoonsgegevens. Wanneer u onze Website en/of Diensten gebruikt, verzamelen en verwerken wij ook andere persoonlijke informatie dan via cookies en soortgelijke technologieën. Als u hierover meer wilt weten, lees dan onze <a href="https://www.tuinaannemer.be/privacyverklaring">Privacyverklaring</a>.</li>
                    <li>Door gebruik te (blijven) maken van deze Website en/of de Diensten, bevestigt u integraal kennis te hebben genomen van deze Cookieverklaring en hiermee integraal akkoord te gaan. Indien u hiermee niet integraal akkoord gaat, verlaat u nu op eigen initiatief de website en maakt u op geen enkele wijze gebruik van deze Website en/of de Diensten, hetzij wijzigt u nu op eigen initiatief uw cookievoorkeuren zoals uiteengezet in deze Cookieverklaring.</li>
                    <li>VLAM behoudt zich het recht voor om de Cookieverklaring (regelmatig) te wijzigen volgens eigen inzicht. Dergelijke wijzigingen, toevoegingen of vervangingen zullen onder uw aandacht worden gebracht via onze Website en/of desgevallend via de Diensten. Als u het niet eens bent met deze wijzigingen, toevoegingen of vervangingen, vragen wij u een e-mail te sturen naar <a href="mailto:multimedia@vlam.be" class="spamspan">multimedia@vlam.be</a>. Indien wij hiervan geen mededeling hebben ontvangen binnen vijf (5) werkdagen nadat de wijzigingen, aanvullingen of vervangingen via onze Website of Diensten aan u zijn meegedeeld, wordt u geacht alle wijzigingen, aanvullingen of vervangingen te hebben aanvaard. Hou er rekening mee dat de intrekking van uw akkoord met de voorwaarden kan impliceren dat u niet langer in staat bent om de Website en/of Diensten geheel of gedeeltelijk te gebruiken.</li>
                    <li>Door de privacy opt-in checkbox aan te klikken op formulieren op de Website, verklaart u dat u inderdaad deze Privacyverklaring gelezen heeft en hiermee instemt. U verklaart dat u begrijpt met welke doeleinden uw persoonsgegevens worden verwerkt. U stemt er ook mee in dat uw voortgezet gebruik van de Website en/of de Diensten wordt begrepen als een voortgezette toestemming. U kan uw toestemming op elk ogenblik intrekken door een verzoek te richten via e-mail naar <a href="mailto:multimedia@vlam.be" class="spamspan">multimedia@vlam.be</a>.</li>
                    <li>Deze cookiesverklaring werd laatst bijgewerkt op 30.05.2018.</li>
                </ul>

                <h2>Welke cookies en soortgelijke technologieën en waarom we deze gebruiken</h2>

                <ul>
                    <li>Wanneer u de diensten van VLAM gebruikt, kan VLAM of een service provider, cookies (gegevensbestandjes op de schijf van uw toestel of mobiele apparaat), webbeacons (elektronische beelden die in de code van een webpagina worden geplaatst) of gelijkaardige technologieën gebruiken. VLAM gebruikt deze cookies en gelijkaardige technologieën, om gegevens te verzamelen, om u te identificeren als gebruiker, om u een betere gebruikerservaring op onze website te bieden, om de promotionele effectiviteit te meten, om services op maat en gerichte advertenties aan te bieden en om vertrouwen en veiligheid te bevorderen en te verzekeren op onze website.</li>
                </ul>

                <p>De VLAM-websites kunnen gebruik maken van verschillende soorten cookies.</p>

                <ul>
                    <li>Wanneer u de Website en/of Diensten bezoekt en/of gebruikt, plaatsen wij cookies of gebruiken we soortgelijke technologieën waarmee we persoonsgegevens over u verzamelen en verwerken. De website kan volgende cookies en soortgelijke technologieën omvatten (de lijst is alomvattend voor alle websites van VLAM en deze website gebruikt niet noodzakelijk alle hieronder vermelde cookies):</li>
                </ul>

                <div class="cookiehub-declaration">
                    <table border="1">
                        <tbody>
                            <tr>
                                <td valign="bottom" width="41">
                                    <p><strong>Naam</strong></p>
                                </td>
                                <td valign="bottom" width="41">
                                    <p><strong>Type</strong></p>
                                </td>
                                <td valign="bottom" width="136">
                                    <p><strong>Functie (doel)</strong></p>
                                </td>
                                <td valign="bottom" width="93">
                                    <p><strong>Bewaartermijn</strong></p>
                                </td>
                                <td valign="bottom" width="166">
                                    <p><strong>Derde verantwoordelijke</strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td width="189">_gat</td>
                                <td width="41">
                                    <p>HTTP cookie</p>
                                </td>
                                <td width="136">
                                    <p>Gebruikt door Google Analytics om verzoeksnelheid te vertragen</p>
                                </td>
                                <td width="93">
                                    <p>sessie</p>
                                </td>
                                <td width="166">
                                    <p>google.com</p>
                                </td>
                            </tr>
                            <tr>
                                <td width="189">_ga</td>
                                <td width="41">HTTP cookie</td>
                                <td valign="bottom" width="136">Registreert een uniek ID die wordt gebruikt om statistische gegevens te genereren over hoe de bezoeker de website gebruikt.</td>
                                <td width="93">sessie</td>
                                <td width="166">google.com</td>
                            </tr>
                            <tr>
                                <td width="189">_gid</td>
                                <td width="41">HTTP cookie</td>
                                <td width="136">Registreert een uniek ID die wordt gebruikt om statistische gegevens te genereren over hoe de bezoeker de website gebruikt.</td>
                                <td width="93">1 dag</td>
                                <td width="166">google.com</td>
                            </tr>
                            <tr>
                                <td width="189">SSESS[Unieke ID]</td>
                                <td width="41">HTTP cookie</td>
                                <td width="136">De sessie cookie van ons content management system, Drupal. Essentieel voor functioneren van de website. Deze cookie registreert bijvoorbeeld of een HTTP gebruiker ingelogd is of niet.</td>
                                <td width="93">23 dagen</td>
                                <td width="166">&nbsp;</td>
                            </tr>
                            <tr>
                                <td width="189">cookieconsent_status</td>
                                <td width="41">HTTP cookie</td>
                                <td width="136">Registreert wanneer de gebruiker het cookie consent bericht heeft bekeken.</td>
                                <td width="93">1 jaar</td>
                                <td width="166">&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <ul>
                    <li>De website heeft de Google Analytics-advertentiefuncties ingeschakeld voor het verkrijgen van demografische- en interesserapporten. Deze gegevens kunnen gebruikt worden voor direct marketing doeleinden. De aldus verkregen informatie wordt, met inbegrip van het adres van uw computer (IP-adres), overgebracht naar en door Google opgeslagen op servers in de Verenigde Staten. Lees het <a href="https://policies.google.com/?hl=nl">privacybeleid van Google</a> voor meer informatie, alsook het specifieke privacybeleid van Google Analytics. U kunt zich afmelden van de Google Analytics Advertentiefuncties via de <a href="https://myaccount.google.com/not-supported?pli=1">Ads Preferences Manager</a> door te kiezen voor ‘afmelden’. Aanvullend kunt u zichzelf uitsluiten van opname in onze webstatistieken door gebruik te maken van deze <a href="https://tools.google.com/dlpage/gaoptout/">Browser Add-on</a>.</li>
                </ul>

                <p>Google gebruikt deze informatie om bij te houden hoe onze website gebruikt wordt, om rapporten over de website aan ons te kunnen verstrekken en om haar adverteerders informatie over de effectiviteit van hun campagnes te kunnen bieden. Google kan deze informatie aan derden verschaffen indien Google hiertoe wettelijk wordt verplicht, of voor zover deze derden de informatie namens Google verwerken. VLAM heeft hier geen invloed op, de verantwoordelijke voor de verwerking is Google LLC, met zetel te 1600 Amphitheatre Parway, Mountain View, CA 94043, VS. VLAM heeft Google alleen toegestaan de verkregen analytics informatie te gebruiken voor diensten van Google zelf.</p>

                <h2>Met wie en waarom wij gegevens delen</h2>

                <ul>
                    <li>Wij verzenden uw persoonsgegevens niet op een identificeerbare manier naar derden als dit niet vereist is om de Diensten te verlenen, zonder uw uitdrukkelijke toestemming daartoe.</li>
                    <li>Wij kunnen een beroep doen op externe verwerkers om u de Website en/of Diensten aan te bieden. Wij zorgen ervoor dat derde verwerkers uw persoonsgegevens alleen namens ons, en op schriftelijke instructie van ons, mogen verwerken. Wij garanderen dat alle externe verwerkers met de nodige zorgvuldigheid worden geselecteerd zodat wij kunnen rekenen op de veiligheid en integriteit van uw persoonsgegevens.</li>
                    <li>Wij kunnen geanonimiseerde en/of geaggregeerde gegevens overmaken aan andere organisaties die deze gegevens kunnen gebruiken om producten en diensten te verbeteren en de marketing, presentatie&nbsp; en verkoop van producten en diensten op maat te organiseren.</li>
                </ul>

                <h2>Waar wij gegevens verwerken</h2>

                <ul>
                    <li>Wijzelf en onze externe verwerkers zullen uw identificeerbare persoonsgegevens alleen in de EER verwerken.</li>
                    <li>Wij kunnen uw geanonimiseerde en/of geaggregeerde gegevens overdragen aan organisaties buiten de EER. Indien een dergelijke overdracht plaatsvindt, zullen wij ervoor zorgen dat er passende waarborgen zijn om de veiligheid en integriteit van uw persoonlijke gegevens te garanderen, en dat alle rechten met betrekking tot persoonsgegevens gegarandeerd worden die u zou kunnen genieten onder het toepasselijk dwingend recht.</li>
                    <li>Als er een overdracht van uw persoonsgegevens en/of geanonimiseerde en/of geaggregeerde gegevens plaatsvindt, wordt het volgende wettelijke beschermingsmechanisme ingevoerd:​</li>
                </ul>

                <table border="1" cellpadding="1" cellspacing="1">
                    <tbody>
                        <tr>
                            <td><strong>Welke</strong></td>
                            <td><strong>Land buiten EER</strong></td>
                            <td><strong>Wettelijke doorgiftemechanisme</strong></td>
                        </tr>
                        <tr>
                            <td>Google</td>
                            <td>USA</td>
                            <td>Bindende bedrijfsvoorschriften</td>
                        </tr>
                    </tbody>
                </table>

                <h2>Hoe wij gegevens verwerken</h2>

                <ul>
                    <li>Wij doen ons uiterste best om alleen de persoonsgegevens te verwerken die nodig zijn om de in deze Cookievoorwaarden vermelde doeleinden te bereiken. Wij zullen uw persoonsgegevens op een wettelijke, eerlijke en transparante manier verwerken. Wij doen ons uiterste best om de persoonsgegevens accuraat en up-to-date te houden.</li>
                    <li>Uw persoonsgegevens worden alleen verwerkt zolang als nodig is om de in deze Cookievoorwaarden vermelde doeleinden te bereiken of tot het moment waarop u uw toestemming voor de verwerking ervan intrekt. Hou er rekening mee dat de intrekking van de toestemming kan impliceren dat u niet langer in staat bent om de Website en/of Diensten geheel of gedeeltelijk te gebruiken. Als u zich bij onze Website heeft geregistreerd (indien van toepassing), zullen wij uw persoonsgegevens verwijderen als u uw profiel verwijdert, tenzij een wettelijke of reglementaire verplichting of een gerechtelijk of administratief bevel ons dat zou verhinderen.</li>
                    <li>Wij zullen de gepaste technische en organisatorische maatregelen nemen om uw persoonsgegevens veilig te houden tegen ongeoorloofde toegang of diefstal en tegen onopzettelijk verlies, manipulatie of vernietiging. Toegang door ons personeel of personeel van onze externe verwerkers is strikt gelimiteerd en alleen mogelijk op een need-to-know-basis en is onderworpen aan strikte geheimhoudingsverplichtingen. U begrijpt echter dat de zorg voor veiligheid en beveiliging slechts inspanningsverplichtingen naar beste vermogen zijn, die nooit kunnen worden gegarandeerd.</li>
                </ul>

                <h2>Uw rechten</h2>

                <ul>
                    <li>U heeft het recht om inzage te vragen tot alle persoonsgegevens die wij over u verwerken. Verzoeken om inzage die klaarblijkelijk worden ingediend met oog op het veroorzaken van overlast of schade voor ons, worden echter niet behandeld.</li>
                    <li>U heeft het recht om te vragen dat alle persoonsgegevens over u die onjuist of onnauwkeurig zijn, kosteloos worden gecorrigeerd. Indien een dergelijk verzoek wordt ingediend, dient u bij dit verzoek ook het bewijs te voegen dat de persoonsgegevens waarvoor correctie wordt gevraagd, onjuist zijn.</li>
                    <li>U heeft het recht om uw eerder gegeven toestemming voor de verwerking van uw persoonsgegevens in te trekken. U kunt uw toestemming te allen tijde intrekken door een e-mail met dit verzoek te sturen naar <a href="mailto:multimedia@vlam.be" class="spamspan">multimedia@vlam.be</a> of door uw profiel te verwijderen (indien van toepassing), of indien u uw toestemming voor de verwerking van uw persoonsgegevens voor een bepaalde Dienst wenst in te trekken kan u de daartoe desgevallend voorziene uitschrijfmogelijkheden benutten.</li>
                    <li>U heeft het recht om te vragen dat persoonsgegevens met betrekking tot u worden verwijderd als deze niet langer nodig zijn in het licht van de doeleinden die in deze Privacyvoorwaarden worden uiteengezet of als u uw toestemming voor de verwerking ervan intrekt. U dient er echter rekening mee te houden dat een verzoek tot verwijdering door ons zal worden beoordeeld in het licht van wettelijke of reglementaire verplichtingen of administratieve of gerechtelijke bevelen die ons kunnen verhinderen de betreffende persoonsgegevens te verwijderen.</li>
                    <li>In de plaats van verwijdering kunt u ook vragen dat wij de verwerking van uw persoonsgegevens beperken indien (a) u de juistheid van die gegevens betwist, (b) de verwerking onwettig is of (c) de gegevens niet langer nodig zijn voor de genoemde doeleinden, maar u ze nodig heeft om uzelf te verdedigen in gerechtelijke procedures.</li>
                    <li>U heeft het recht om zich te verzetten tegen de verwerking van persoonsgegevens indien u kunt aantonen dat er ernstige en gerechtvaardigde redenen zijn die verband houden met bijzondere omstandigheden en die een dergelijk verzet rechtvaardigen. Als de beoogde verwerking echter als direct marketing wordt aangemerkt, heeft u het recht zich kosteloos en zonder rechtvaardiging te verzetten tegen een dergelijke verwerking.</li>
                    <li>Wanneer uw persoonsgegevens worden verwerkt op basis van toestemming of op basis van een contract en de verwerking met geautomatiseerde middelen gebeurt, heeft u het recht om de door u aan VLAM verstrekte persoonsgegevens in een gestructureerd, algemeen gebruikt en machinaal leesbaar formaat te ontvangen en, indien technisch mogelijk, heeft u het recht deze gegevens rechtstreeks door te geven aan een andere dienstverlener. De technische haalbaarheid hiervan wordt uitsluitend beoordeeld door VLAM.</li>
                    <li>Als u een verzoek wilt indienen om één of meer van de hierboven vermelde rechten uit te oefenen, kunt u een verzoek sturen naar VLAM vzw – afd. Multimedia, Koning Albert II-laan 15 bus 411, 1210 Brussel (postadres) of via e-mail met in bijlage dit verzoek sturen naar <a href="mailto:multimedia@vlam.be" class="spamspan">multimedia@vlam.be</a>. In dit verzoek moet duidelijk worden vermeld welk recht u wenst uit te oefenen en waarom. Het moet ook gedateerd en ondertekend zijn en vergezeld gaan van een digitaal gescande kopie van uw geldige identiteitskaart waaruit uw identiteit blijkt. Wij zullen u binnen een redelijke termijn op de hoogte stellen van de ontvangst van dergelijk verzoek. Indien de aanvraag gegrond blijkt te zijn, zullen wij deze zo spoedig als redelijkerwijs mogelijk is en uiterlijk dertig (30) dagen na ontvangst van de aanvraag inwilligen zonder verder tegenbericht. Indien de aanvraag ongegrond zou blijken te zijn, zullen wij u op de hoogte brengen van de weigering en de reden er van.</li>
                    <li>Als u een klacht heeft over de verwerking van uw persoonsgegevens door ons, kunt u altijd contact met ons opnemen via het e-mailadres <a href="mailto:juridischedienst@vlam.be" class="spamspan">juridischedienst@vlam.be</a>. Blijft u ontevreden over ons antwoord, dan staat het u vrij om een klacht in te dienen bij de Vlaamse Ombudsdienst en/of de bevoegde gegevensbeschermingsautoriteit, de Belgische Privacycommissie. Ga voor meer informatie naar <a href="http://www.vlaamseombudsdienst.be">http://www.vlaamseombudsdienst.be</a> en <a href="http://www.privacycommission.be">http://www.privacycommission.be</a>.</li>
                </ul>

                <h2>Zelf uw cookievoorkeuren instellen</h2>

                <ul>
                    <li>Via uw browserinstellingen kunt u de installatie van cookies weigeren. U kunt ook op elk moment de reeds geïnstalleerde cookies verwijderen van uw computer of mobiel apparaat. Hulp bij het aanpassen van deze instellingen kunt u vinden op de website van de browser die u gebruikt. Houd er rekening mee dat het niet toestaan van cookies bij het bezoeken van de Website ertoe kan leiden dat sommige of alle functies van de Website niet naar behoren werken.
                        <ul>
                            <li>Internet Explorer: <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</a>;</li>
                            <li>Microsoft Edge: <a href="http://windows.microsoft.com/en-gb/windows-10/edge-privacy-faq">http://windows.microsoft.com/en-gb/windows-10/edge-privacy-faq</a>;</li>
                            <li>Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=en">https://support.google.com/chrome/answer/95647?hl=en</a>;</li>
                            <li>Firefox: <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a>;</li>
                            <li>Safari: <a href="https://support.apple.com/nl-be/guide/safari/sfri11471/mac">https://support.apple.com/nl-be/guide/safari/sfri11471/mac</a>;</li>
                        </ul>
                    </li>
                    <li>Via <a href="http://www.youronlinechoices.com/nl/">‘Your online choice’</a> kunt u zelf bepalen of u wel of geen gebruik wilt maken van interested based advertising.</li>
                    <li>Voor verdere informatie over het verwijderen of blokkeren van cookies kan je volgende website bezoeken: <a href="http://www.aboutcookies.org">http://www.aboutcookies.org</a>.</li>
                </ul>
                <div className='vlam mt-10 space-y-5'>
                    <h4 className='text-xl'>Vlaams Centrum voor Agro- en Visserijmarketing</h4>
                    <a className='inline-block' href="https://www.vlam.be" target="_blank" rel="noreferrer">
                        <img alt={'vlam logo'} className='w-32' src={vlamLogo} />
                    </a>
                    <div className='address flex flex-col'>
                        <p><strong>Ons adres</strong></p>
                        <p>
                            Koning Albert II-laan 15 bus 411,  <br></br>
                            1210 Brussel (postadres)
                        </p>
                    </div>
                    <div className='contact'>
                        <p><strong>Contactgegevens</strong></p>
                        <div className='flex flex-col items-start'>
                            <a href="tel:025532000">Tel: 02 553 20 00</a>
                            <a href="mailto:info@tuinaannemer.be">info@tuinaannemer.be</a>
                            <a href="https://www.vlam.be" target="_blank" rel="noreferrer">www.vlam.be</a>
                        </div>
                    </div>
                    <p>© 2024 VLAM vzw</p>
                </div>
            </div>
        </div>
    )
}